.app {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.profile-img {
  border-radius: 50%;
  height: 200px;
}

.app-link {
  color: #61dafb;
}
